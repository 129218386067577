import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../css/custom-css/custom-404.css"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const NotFoundPage = () => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""

  return (
    <>
      <div className="dlab-bnr-inr full-height overlay-black-dark">
        <div className="container">
          <div className="row max-w700 dlab-bnr-inr-entry dz_error-404-area align-m dz_error-404 text-white m-auto">
            <div className="col-lg-4 col-md-4 m-tb30">
              <div className="bg-white dz_error">
                <span className="text-grey">404</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 m-b30 text-left">
              <h2 className="error-head">{intl.formatMessage({ id: "404.1" })}</h2>
              <p className="font-16">{intl.formatMessage({ id: "404.2" })}</p>
              <p className="font-16">{intl.formatMessage({ id: "404.3" })}</p>
              <Link to={`${locale}/`} className="site-button">
                <span className="text-black">
                  {intl.formatMessage({ id: "404.btn" })}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
